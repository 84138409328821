#dates {
  display: flex;
  flex-direction: row;
  padding-left: 0;
  align-items: center;
  width: 280px;

  #dateSeparator {
    margin: 0 25px;
  }
}
