.defaultStatusHolder {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  border-radius: 20px;
  font-size: 8pt;
  padding: 4px 0;

  .statusIcon {
    height: 14px;
    width: 14px;
    margin-right: 2px;
  }
}

.defaultStatusActive {
  background-color: #cefee4;
  color: #189258;
  border-color: #189258;
}

.defaultStatusInactive {
  background-color: #fecece;
  color: #870606;
  border-color: #870606;

  .statusIcon {
    height: 13px;
    width: 13px;
  }
}
