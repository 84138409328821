.formContainer {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  margin: 0;
  padding: 15px 20px 10px 20px;
  border: none;

  #searchText {
    color: var(--cm-primary);
    font-size: 12pt;
    font-weight: bold;
  }

  #searchText.searchTextLabel {
    margin-bottom: -5px;
  }
}

#statusHolder {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
