.pageHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--cm-primary);
  padding: 8px 0 10px 20px;
  border-bottom: 2px solid #d4d4e4;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.08);
  flex-wrap: wrap;

  .textHolder {
    display: flex;
    flex-direction: column;

    .mainLvl {
      font-size: 12pt;
    }

    .pageName {
      font-size: 18pt;
      font-weight: bold;

      @media (max-width: 450px) {
        font-size: 16px;
      }
    }
  }

  .buttonsHolder {
    display: flex;
    flex-direction: row;

    .addButton {
      background-color: #e4eafc !important;
      margin-right: 30px;
    }

    .marginRight {
      margin-right: 30px;
    }

    .buttonBase {
      display: table;
      text-decoration: none;
      padding: 5px 15px;
      border-radius: 6px;
      border: 1px solid var(--cm-primary);
      font-size: 12pt;
      font-weight: bold;
      color: var(--cm-primary);
      background-color: white;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

      @media (max-width: 450px) {
        font-size: 12px;
        padding: 6px 15px;
      }
    }
  }
}
