.tableHolderReport {
  margin-top: 20px;
  height: 40vh;

  .resultTable {
    border-right: 1px solid #d4d4e4;

    .tableHeader {
      display: table-header-group;
      border-top: 1px solid #d4d4e4;
      border-bottom: 1px solid #d4d4e4;

      th {
        padding: 8px 15px;
        font-size: 11pt;
        font-weight: bold;
        color: black;
        background-color: white;
        border-left: 1px solid #d4d4e4;
      }

      .actionCell {
        width: 120px;
        min-width: 120px;
        text-align: center;
        border-left: 1px solid #d4d4e4;
      }
    }

    .zebraStyle {
      td {
        background-color: #f5f5fb !important;
      }
    }

    .tableRow {
      page-break-inside: avoid;

      td {
        height: 40px;
        padding: 8px 15px;
        border-bottom: 1px solid #d4d4e4;
        border-left: 1px solid #d4d4e4;
        background-color: white;
      }

      .actionCell {
        border-left: 1px solid #d4d4e4;
        padding: 0 25px;

        .actionButtonsHolder {
          display: flex;
          flex-direction: row;
          color: var(--cm-primary);
          justify-content: space-between;
          align-items: center;
          font-size: 14pt;

          .icon {
            cursor: pointer;
          }

          hr {
            height: 20px;
            display: block;
            width: 2px;
            border: 0;
            border-left: 1px solid #d4d4e4;
            padding: 0;
          }
        }
      }
    }
  }
}

.tablePaginationHolder {
  width: 100%;
  flex-grow: 1;
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 20px;

  .tablePagination {
    tr {
      td {
        border: 0;
      }
    }
  }
}

.tableHolderReportNewLayout {
  margin-top: 20px;
  height: 40vh;

  table,
  td,
  tr,
  th {
    border: none;
  }

  .resultTableNewLayout {
    .tableBreak {
      border: 1px solid #d4d4e4;

      td {
        font-weight: bold;
        color: var(--cm-primary);
      }

      .spaceLeft {
        margin-left: 20px;
      }
    }

    .tableHeaderNewLayout {
      display: table-header-group;
      border-top: 1px solid #d4d4e4;
      border-left: 1px solid #d4d4e4;
      border-right: 1px solid #d4d4e4;

      th {
        border-left: 1px solid #d4d4e4;
        font-weight: bold;
        height: 20px;
      }
    }

    .tableRowNewLayout {
      page-break-inside: avoid !important;

      td {
        height: 40px;
        padding: 8px 15px;
        border: 1px solid #d4d4e4;
        background-color: white;
      }
    }
  }
}
