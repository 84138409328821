.dicountHolder {
  display: flex;
  border-radius: 30px;
  background-color: #efeff6;
  justify-content: center;
  align-items: center;
  padding: 30px;
  margin: 15px 0;

  span {
    font-size: 35px;
    color: var(--cm-primary);
  }

  #value {
    font-weight: bold;
  }
}
