.passwordInformationContainer {
  padding: 8px 24px 0px 24px;
  color: #777;

  .passwordInformationTitle {
    margin-left: -24px;
    margin-bottom: 8px;
  }

  .passwordVerified {
    color: var(--cm-secondary);
  }
}