.recoverContainerRequest {
  margin-top: -50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: transparent;
  border-top: 3px solid var(--cm-primary);

  .content {
    display: flex;
    flex-direction: column;
    border: 1px solid #dfdfeb;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);

    .header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: white;
      width: 100%;
      border: none;

      div {
        margin-top: 10px;
        text-align: center;
        padding: 10px 30px;
        color: var(--cm-primary);
        font-size: 14pt;
        font-weight: 700;
      }

      img {
        margin-top: 25px;
        width: 100px;
        height: 72px;
      }
    }

    .form {
      width: 100%;
      margin: 0 !important;
      background-color: white;
      padding: 0 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border: none;

      @media screen and (max-width: 450px) {
        padding: 0 40px;
      }

      .formInput {
        display: flex;
        flex-direction: column;
        width: 100%;

        div {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-around;
          margin-bottom: 10px;
        }

        span {
          color: var(--cm-primary);
          font-size: 12pt;
          font-weight: bold;
        }

        span.title {
          font-weight: normal;
        }

        input {
          height: 30px;
          margin-bottom: 10px;
          margin-top: 5px;
          padding: 0 20px;
          color: #777;
          font-size: 15px;
          width: 100%;
          border: 1px solid #ddd;
          border-radius: 5px;
          &::placeholder {
            color: #999;
          }
        }
      }

      .formInput:nth-child(2) {
        margin-top: 5px;
      }

      button {
        padding: 10px 15px;
        margin-top: 10px;
        margin-bottom: 22px;
        border-radius: 4px;
        font-size: 12px;
        font-weight: bold;
        color: var(--cm-primary-contrast);
        border: 1px solid var(--cm-primary-shade);
        background-color: var(--cm-primary);
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
      }
    }
  }
}
