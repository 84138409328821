.Toastify__toast--success {
  background-color: var(--toast-bg-success-blue);
}

.Toastify__toast--info {
  background-color: var(--toast-bg-success-green);
}

// .Toastify__toast--warning {}
// .Toastify__toast--error {}

.Toastify__toast-body {
  width: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

.defaultFormContainer {
  .defaultForm {
    margin: 0;
    padding: 0;
    background-color: #f2f2f2;
    border: 0;

    .infoCard {
      background-color: #fcfcfc;
      margin-top: 10px;
      padding: 20px;

      #title {
        color: var(--cm-primary);
        font-weight: bold;
      }

      #subtitle {
        color: var(--cm-primary);
      }

      .addMarginTop {
        margin-top: 10px;
      }
    }

    .infoCard.noPaddingTop {
      padding-top: 0;
    }

    .infoCard.noMarginTop {
      margin-top: 0;
    }

    .infoCard.noPadding {
      padding: 0 0 2px 0 !important;
    }

    .infoCardBase {
      margin-top: -20px;
      background-color: none;
    }

    span.textFieldRequired {
      color: #fd1616;
    }
  }
}

.rowContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  flex-wrap: wrap;

  .rowContent {
    display: flex;
    flex-direction: row;
  }

  .defaultFlex {
    flex: 1;
  }

  .noMarginRight {
    margin-right: 0 !important;
  }

  .inputHolder {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    min-width: 160px;

    #helpText {
      font-size: 12pt;
      color: var(--cm-primary);
    }

    span {
      color: var(--cm-primary);
    }

    select,
    input,
    textarea {
      height: 33px;
      border-radius: 6px;
      border: 1px solid #dadae3;
      padding: 8px 15px;
      margin-top: 5px;
    }

    select:focus,
    input:focus,
    textarea:focus {
      border: 1px solid var(--cm-primary);
    }

    select:disabled,
    input:disabled,
    textarea:disabled {
      background-color: rgba(197, 197, 212, 0.15) !important;
    }

    select {
      padding: 0 15px;
    }

    textarea {
      height: auto;
    }
  }

  .inputHolder.marginRightX4 {
    margin-right: 20px * 4;
  }

  .inputHolder.addMarginBottom {
    margin-bottom: 10px;
  }

  .inputHolder.inputHolderSmall {
    min-width: 70px;
  }

  .inputWithNumbers {
    min-width: 140px;
    width: 140px;
  }
}

.rowContainer.rowContainerCenter {
  align-items: baseline;
}

.rowContainer.rowContainerEnd {
  justify-content: end;
}

.rowContainer.rowAlignItemsEnd {
  align-items: flex-end;
}

.rowContainer.rowJustifyContent {
  justify-content: space-between;
}

.rowContainer.noMarginTop {
  margin-top: 0px;
}

.rowContainer.rowCenter {
  align-items: center;
}

.widthPrazoRenovacaoChave {
  width: 61px !important;
  text-align: center;
}

.imagePicker {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .buttonHolder {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 15px;
    border: 1px solid var(--cm-primary);
    border-radius: 6px;
    min-width: 150px;
    height: 40px;
    cursor: pointer;

    span {
      color: var(--cm-primary);
      font-weight: bold;
      font-size: 12px;
      cursor: pointer;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      width: 150px;
      height: 40px;
      padding: 0;
      margin: 0;
      z-index: 2;
    }
  }

  label {
    margin-left: 15px;
    color: #7e7e7e;
  }
}

.radioHolder {
  display: flex;
  flex-direction: row;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 5px;
  margin-right: 10px;
  justify-content: center;
  align-items: center;

  .inputBoxHolder {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    height: 24px;
    width: 24px;
    justify-content: center;
    align-items: center;

    input {
      position: absolute;
      cursor: pointer;
      opacity: 0;
      margin: 0;
      z-index: 2;
    }

    .checkmark {
      height: 24px;
      width: 24px;
      background-color: white;
      border: 1px solid #d4d4df;
      border-radius: 4px;

      .markHolder {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;

        .checkmark_stem {
          position: absolute;
          width: 2px;
          height: 15px;
          background-color: var(--cm-primary);
          -ms-transform: rotate(45deg) translate(2px, -3px); /* IE 9 */
          -webkit-transform: rotate(45deg) translate(2px, -3px); /* Chrome, Safari, Opera */
          transform: rotate(45deg) translate(2px, -3px);
        }

        .checkmark_kick {
          position: absolute;
          width: 10px;
          height: 2px;
          background-color: var(--cm-primary);
          -ms-transform: rotate(45deg) translate(-3px, 4px); /* IE 9 */
          -webkit-transform: rotate(45deg) translate(-3px, 4px); /* Chrome, Safari, Opera */
          transform: rotate(45deg) translate(-3px, 4px);
        }
      }
    }

    .checkradio {
      height: 20px;
      width: 20px;
      background-color: white;
      border: 1px solid #d4d4df;
      border-radius: 10px;

      .markRadioHolder {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;

        .checkradio_stem {
          position: absolute;
          width: 12px;
          height: 12px;
          border-radius: 8px;
          background-color: var(--cm-primary);
        }
      }
    }

    .checkradio.checkradio_outline {
      border-color: #4c4e9e;
    }
  }

  #inputHolder:hover input ~ .checkmark {
    border: 1px solid #4c4e9e;
    background-color: #eee;
  }

  #inputHolder input:checked ~ .checkmark {
    border: 1px solid var(--cm-primary);
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  #inputHolder input:checked ~ .checkmark:after {
    display: block;
  }

  #radioLabel {
    padding-left: 10px;
    color: var(--cm-primary);
  }
}

@media print {
  .updateStatusContainer {
    display: none;
  }

  .orderManagementContainer {
    background-color: white;
  }

  @page {
    size: auto;
    margin: 5mm 0;
  }
}
