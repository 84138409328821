button {
  padding: 10px 15px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  color: var(--cm-primary-contrast);
  border: 1px solid var(--cm-primary-shade);
  background-color: var(--cm-primary);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
}

.outline {
  color: var(--cm-tertiary-contrast);
  border: 1px solid var(--cm-tertiary-contrast);
  background-color: var(--cm-tertiary);
}

.disable {
  opacity: 0.5;
  cursor: not-allowed;
}
