.formButton {
  opacity: 0.5;
  z-index: 2;
  cursor: pointer;
}

.formActions {
  display: flex;
  flex-direction: row;

  width: auto;

  svg {
    width: 16px !important;
    height: 16px !important;
  }

  svg + svg {
    margin-left: 10px;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 188px;
  background-color: #555;
  color: #fff !important;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  left: -10px;
  bottom: 90%;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
