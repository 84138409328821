#radioLabel {
  color: #7e7e7e;
}

.solicitationStatusHolder {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.justifyContentEnd {
  justify-content: flex-end;
}

.justifyContentStart {
  justify-content: flex-start !important;
}

.messageLeft {
  display: flex;
  justify-content: flex-end;
}

.messageRight {
  display: flex;
  justify-content: flex-start;
}

.messageContainer {
  border: 1px solid lightgray;
  border-radius: 8px;
  margin: 10px 20px;
  padding: 15px;
  width: 90%;

  #messageHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    #messageNumber {
      color: #9caabf;
      font-weight: bold;
    }

    #messageOwner {
      color: black;

      #userName {
        font-weight: bold;
      }
    }
  }

  #input-message-id {
    textarea {
      background-color: transparent !important;
    }
  }

  .anexosHolder {
    display: flex;
    flex-direction: row;
  }
}

.messageContainer.messageContainerOld {
  width: auto;
}

.attach {
  margin: 5px 10px;
}

.attachsContainer {
  padding: 0 !important;
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    flex-direction: column;
  }
}

.mensagemStatus {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 62px;
  border-radius: 6px;
  padding: 16px;
  font-size: 27px;
  border: 3px solid #000000;
  font-style: normal;
  font-weight: bold;
  margin-top: 16px;
  background-color: #ffd037;
  color: #000000;
}

.mensagemStatus.aprovado {
  background-color: #109839;
  color: #ffffff;
}

.mensagemStatus.negado {
  background-color: #f60000;
  color: #ffffff;
}

.mensagemStatus.antigo {
  background-color: #6d6d6d;
  color: #ffffff;
}

.alignFooter {
  align-items: flex-end;
}

.buttonSend {
  height: 38px;
}

.mensagemStatusLabel {
  display: flex;
  margin-left: 12px;
  align-items: center;
  font-weight: normal;
  color: var(--cm-primary);
}

.mensagemStatusCircle {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  margin-right: 3px;
  border: 1px solid #dda900;
  background-color: #ffd037;
}

.mensagemStatusCircle.aprovado {
  border: 1px solid #207c00;
  background-color: #109839;
}

.mensagemStatusCircle.negado {
  border: 1px solid #a90000;
  background-color: #f60000;
}

select[name="tipo"]:disabled {
  background-color: rgba(197, 197, 212, 0.15) !important;
}
