nav {
  position: sticky;
  top: 0;
}

.suggestions-container {
  border: 1px solid #999;
  border-radius: 8px;
  padding: 8px;
}

.suggestion-active {
  border: 1px solid var(--cm-primary);
  border-radius: 8px;
  z-index: 3;
}

.no-suggestions {
  color: #999;
  padding: 0.5rem;
  width: calc(300px + 1rem);
}

.suggestions {
  background: #fff;
  color: #707070;
  list-style: none;
  margin-top: 0;
  max-height: auto;
  overflow-y: auto;
  padding-left: 0;
  width: calc(300px + 1rem);
}

.suggestions.suggestions-full {
  width: 100%;
  padding-right: 8px;
}

.suggestions li {
  padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
  cursor: pointer;
}
