.iconHolder {
  text-align: center;

  .EditIconOnTable {
    color: var(--cm-primary);
    font-size: 18pt;
    cursor: pointer;
    height: 20px;
    width: 100%;
  }
}

.widthCpfCnpj {
  width: calc(300px + 1rem) !important;
}
