.attachmentOrderModal {
  min-width: 520px;
  max-width: 520px;
  z-index: 3;

  @media screen and (max-width: 600px) {
    min-width: calc(100% - 20px);
  }

  .attachmentOrderModalHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    span {
      color: var(--cm-primary);
      font-weight: bold;
      font-size: 16pt;
    }

    .closeIcon {
      color: var(--cm-primary);
      font-size: 18pt;
      cursor: pointer;
    }
  }

  .attachmentOrderModalId {
    font-weight: 600;
    margin-bottom: 15px;

    span {
      font-weight: bold;
    }
  }

  .anexosHolder {
    background-color: #c6c6dc36;
    margin-bottom: 15px;
    padding: 0 0 10px 10px;
    display: flex;
    flex-wrap: wrap;
  }

  .sendOrderAnexosButtonHolder {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0 5px 0;
  }
}
