input {
  width: 100%;
  height: 33px;
  border-radius: 6px;
  border: 1px solid #dadae3;
  padding: 8px 15px;
  margin-top: 5px;
}

input:focus {
  border: 1px solid var(--cm-primary);
}

input:disabled {
  background-color: rgba(197, 197, 212, 0.15) !important; // #C5C5D4
}
