#messageHelper {
  font-weight: bold;
  color: red;
  text-align: center;
  background-color: #ff0;
  padding: 6px;
  margin-bottom: 12px;
  border-radius: 6px;
}

.formContainer {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  margin: 0;
  padding: 15px 20px 10px 20px;
  border: none;

  #searchText {
    color: var(--cm-primary);
    font-size: 12pt;
    font-weight: bold;
  }
}

#statusHolder {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
