#subtitle {
  color: var(--cm-primary);
}

#label-option {
  color: var(--cm-primary);
  align-self: flex-start;
}

#label-checkbox {
  color: var(--cm-primary);
  align-self: center;
  margin-left: 4px;
}

#form-types {
  width: 100%;
}

textarea {
  min-width: 100%;
  min-height: 75px;
  max-height: 132px;
}

input[type="checkbox"].required-item-form {
  width: 11px !important;
}
