.iconHolder {
  text-align: center;
}

.iconOnTable {
  font-size: 18pt;
  height: 20px;
  width: 100%;
}

.channelRatingContainer {
  width: 100%;

  .modalContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    text-align: center;

    h2 {
      color: #707070;
      padding-bottom: 10px;
    }

    span {
      font-size: 18px !important;
    }

    div {
      display: flex;
      flex: 1;
      width: 100%;
      padding-top: 25px;
      justify-content: center;
    }

    #elementName {
      color: var(--cm-primary);
      font-weight: 600;
    }

    .cancelButton {
      margin-right: 30px;
    }
  }

  #discountHolder {
    display: flex;
    flex-direction: column;
    height: auto;
  }
}
