.modulePriceHolder {
  display: flex;
  flex-direction: row;
  background-color: #F6F6F6;
  border-radius: 8px;
  padding: 8px 15px;
  margin: 0 10px;
}

.noMarginLeft {
  margin-left: 0 !important;
}

.justifyFlexStart {
  justify-content: flex-start !important;
}
