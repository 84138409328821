.formContainer {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  margin: 0;
  padding: 15px 20px 10px 20px;
  border: none;

  #searchText {
    color: var(--cm-primary);
    font-size: 12pt;
    font-weight: bold;
    margin-bottom: 10px;
  }
}
