#title-table-search {
  margin-top: 20px;
  margin-left: 20px;
  color: var(--cm-primary);
  margin-bottom: -16px;
}

#title-table-selected {
  margin-top: 20px;
  margin-left: 20px;
  color: var(--cm-primary);
}

.containerDeleteIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.addMarginRight {
  margin-right: 16px;
}

.deleteIcon {
  display: flex;
  flex-direction: row;
  color: var(--cm-primary);
  justify-content: space-between;
  align-items: center;
  font-size: 14pt;
  min-width: 26px;

  cursor: pointer;
}
