.editCancelationContainer {
  display: flex;
  flex-direction: column;
  background: #fcfcfc;
  margin: 10px 0;
}

.editCancelationButtonBase {
  margin-right: 20px;
}

.editCancelationCancelButton {
  background: #e4eafc !important;
}

.containerObs {
  width: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}
