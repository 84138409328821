.profileImageContainer {
  flex: 0.35;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  background-color: #fcfcfc;

  img {
    height: 200px;
    width: 200px;
    min-width: 200px;
    min-height: 200px;
    border-radius: 100%;
    border: 2px solid var(--cm-primary);
  }

  label {
    margin: 15px 0;
  }

  .imagePicker {
    width: 240px;
    .buttonHolder {
      width: 240px;
      input {
        width: 240px;
      }
    }
  }
}

.profileFormContainer {
  flex: 0.65;
  height: 100%;
}

.profileDefaultForm {
  background-color: #fcfcfc !important;

  .infoCard {
    width: 100%;
  }
}
