.tableHolder {
  margin: 20px;
  border-right: 1px solid #d4d4e4;

  .resultTable {
    .tableHeader {
      border-top: 1px solid #d4d4e4;
      border-bottom: 1px solid #d4d4e4;

      th {
        padding: 8px 15px;
        font-size: 11pt;
        font-weight: bold;
        color: black;
        background-color: white;
        border-left: 1px solid #d4d4e4;
      }

      .actionCell {
        width: 120px;
        min-width: 120px;
        text-align: center;
        border-left: 1px solid #d4d4e4;
      }
    }

    .zebraStyle {
      td {
        background-color: #f5f5fb !important;
      }
    }

    .tableRow {
      td {
        height: 40px;
        padding: 8px 15px;
        border-bottom: 1px solid #d4d4e4;
        border-left: 1px solid #d4d4e4;
        background-color: white;
      }

      .actionCell {
        border-left: 1px solid #d4d4e4;
        padding: 0 25px;
        width: 138px;

        .actionButtonsHolder {
          display: flex;
          flex-direction: row;
          color: var(--cm-primary);
          justify-content: space-between;
          align-items: center;
          font-size: 14pt;
          min-width: 44px;

          .icon {
            cursor: pointer;
          }

          .icon-lock {
            font-size: 16pt;
          }

          .icon-disabled {
            cursor: not-allowed;
          }

          hr {
            height: 20px;
            display: block;
            width: 2px;
            border: 0;
            border-left: 1px solid #d4d4e4;
            padding: 0;
          }

          button {
            box-shadow: none;
          }
        }

        .actionButtonsHolder.buttonCenterHolder {
          justify-content: center;
        }
      }

      .actionCell.widthAuto {
        width: auto;
      }
    }

    .tableRow.noPaddingVertical {
      td {
        padding: 0 15px;
      }
    }
  }
}

.actionButtonsHolderButton {
  display: flex;
  flex-direction: row;
  color: var(--cm-primary);
  justify-content: space-between;
  align-items: center;
  font-size: 14pt;
  min-width: 26px;
  cursor: pointer;
}

.tablePaginationHolder {
  width: 100%;
  flex-grow: 1;
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 20px;
  margin-bottom: 20px;

  .tablePagination {
    tr {
      td {
        border: 0;
      }
    }

    button {
      box-shadow: none;
    }
  }
}

.tableHeaderLabelAll {
  display: flex;
  align-items: center;

  span.labelAll {
    margin-left: 12px;
  }

  div>label.container {
    margin: 0px;
    border: none;
    display: inline;
  }
}