.orderManagementContainer {
  flex: 1;
  margin: 0;
  padding: 15px 0;
  background: #fcfcfc;
  flex-direction: column;
}

.updateStatusContainer {
  display: flex;
  flex-direction: column;
  margin: 10px 20px;
  background: white;

  .updateStatusHeader {
    width: 100%;
    color: var(--cm-primary);
    font-weight: bold;
    border: 1px solid var(--cm-primary);
    border-radius: 8px;
    padding: 5px 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
  }

  .updateStatusContent {
    display: flex;
    flex-direction: column;
    background: white;
    border-left: 1px solid #d4d4e4;
    border-right: 1px solid #d4d4e4;
    border-bottom: 1px solid #d4d4e4;
    border-radius: 8px;
    padding: 15px;

    .updateStatusOptions {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0 10px;
    }

    .obsHolder {
      display: flex;
      flex-direction: column;
      margin: 10px 20px;
      min-width: 160px;

      span {
        color: var(--cm-primary);
      }

      textarea {
        height: auto;
        border-radius: 6px;
        border: 1px solid #dadae3;
        padding: 8px 15px;
        margin-top: 5px;
        max-height: none;
        resize: vertical;
        overflow: auto;
      }

      textarea:focus {
        border: 1px solid var(--cm-primary);
      }
    }

    .updateHolder {
      display: flex;
      justify-content: flex-end;
      margin: 0 20px;
    }
  }
}
