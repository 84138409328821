.collapsibleHolder {
  page-break-before: avoid; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
  // page-break-after: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
  // page-break-inside: avoid; /* or 'auto' */

  .collapsibleHeader {
    color: var(--cm-primary);
    font-weight: bold;
    border: 1px solid var(--cm-primary);
    border-radius: 8px;
    background: white;

    page-break-before: avoid; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
    // page-break-after: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
    // page-break-inside: avoid; /* or 'auto' */
  }

  .collapsibleContent {
    background: white;
    border-left: 1px solid #d4d4e4;
    border-right: 1px solid #d4d4e4;
    border-bottom: 1px solid #d4d4e4;
    border-radius: 8px;
  }

  .collapsibleContent.no-items {
    page-break-before: avoid; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
    // page-break-after: avoid; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
    // page-break-inside: avoid; /* or 'auto' */
  }

  .collapsibleContent.with-items {
    page-break-before: avoid; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
    // page-break-after: avoid; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
    page-break-inside: avoid; /* or 'auto' */
  }
}
