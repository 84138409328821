.container {
  margin-top: -50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: transparent;
  border-top: 3px solid var(--cm-primary);

  .containerWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .content {
    display: flex;
    padding: 32px 24px;
    gap: 7px;
    flex-direction: column;
    border: 1px solid #dfdfeb;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
    max-width: 443px;
    color: var(--cm-primary);

    header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      border: none;

      h4 {
        color: var(--cm-primary);
      }
    }

    .description {
      font-size: 16px;
    }

    .background-form {
      background-color: white;

      .label-code {
        font-size: 12px;
        font-weight: 400;
      }

      input {
        height: 30px;
        margin-bottom: 10px;
        margin-top: 5px;
        padding: 0 20px;
        color: #777;
        font-size: 15px;
        width: 100%;
        border: 1px solid #ddd;
        border-radius: 5px;
        &::placeholder {
          color: #999;
        }
      }
    }

    .actions {
      justify-content: center;
      gap: 24px;
    }
  }
}
