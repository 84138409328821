.recoverContainer {
  margin-top: -50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: transparent;
  border-top: 3px solid var(--cm-primary);

  .content {
    display: flex;
    flex-direction: column;
    border: 1px solid #dfdfeb;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);

    .header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: white;
      width: 100%;
      border: none;

      div {
        margin-top: 10px;
        text-align: center;
        padding: 10px 30px;
        color: var(--cm-primary);
        font-size: 14pt;
        font-weight: 700;
      }

      img {
        margin-top: 25px;
        width: auto;
        height: 100px;
      }
    }

    .form {
      width: 100%;
      margin: 0 !important;
      background-color: white;
      padding: 0 80px;
      display: flex;
      flex-direction: column;
      border: none;

      @media screen and (max-width: 450px) {
        padding: 0 40px;
      }

      div {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 10px;

        button {
          padding: 10px 15px;
          margin-top: 10px;
          margin-bottom: 15px;
          border-radius: 4px;
          font-size: 12px;
          font-weight: bold;
          color: var(--cm-primary-contrast);
          border: 1px solid var(--cm-primary-shade);
          background-color: var(--cm-primary);
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
        }

        .resetPassword {
          color: var(--cm-tertiary-contrast);
          border: 1px solid var(--cm-tertiary-contrast);
          background-color: var(--cm-tertiary);
          margin-right: 20px;
        }
      }

      span {
        color: var(--cm-primary);
        font-size: 12pt;
        font-weight: bold;
      }

      span.title {
        font-weight: normal;
      }

      input {
        height: 30px;
        margin-bottom: 10px;
        margin-top: 5px;
        padding: 0 20px;
        color: #777;
        font-size: 15px;
        width: 100%;
        border: 1px solid #ddd;
        border-radius: 5px;
        &::placeholder {
          color: #999;
        }
      }
    }
  }
}
