.orderManagementOldStatusContainer {
  width: 100%;
  padding: 20px;

  .orderManagementOldStatusSeparatorHelper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    page-break-before: avoid;
    page-break-inside: avoid;

    .orderManagementOldStatusHolder {
      display: flex;
      flex-direction: column;
      background: #f5f5fb;
      border-radius: 8px;
      padding: 5px 20px;

      .orderManagementOldStatusHeader {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        span {
          margin: 0 5px;
        }

        .orderManagementStatusDate {
          color: gray;
        }

        .orderManagementStatus {
          color: var(--cm-primary);
          font-weight: 600;
        }

        .orderManagementStatusOwner {
          color: #1a3e62;
        }
      }

      .orderManagementOldStatusObs {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 5px;

        .containerObs {
          width: 100%;
          overflow-wrap: break-word;
          word-wrap: break-word;
          word-break: break-word;
        }
      }
    }

    .orderManagementCurrentStatus {
      border: 1px solid #1a3e62;
    }

    .orderManagementOldStatusSeparator {
      height: 20px;
      width: 1px;
      border-left: dotted 1px var(--cm-primary);
      margin-left: 170px;
    }
  }
}
