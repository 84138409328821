.collapsibleHeader {
  color: var(--cm-primary);
  font-weight: bold;
  border: 1px solid var(--cm-primary);
  border-radius: 8px;
  background: white;
}

.collapsibleContent {
  background: white;
  border-left: 1px solid #d4d4e4;
  border-right: 1px solid #d4d4e4;
  border-bottom: 1px solid #d4d4e4;
  border-radius: 8px;
}
