#title {
  color: var(--cm-primary);
  font-weight: bold;
}

#quantityHolder {
  display: flex;
  flex-direction: row;
  border: 1px solid #e1e1e9;
  border-radius: 5px;
  background-color: white;
  height: 30px;
  width: 100px;
  justify-content: center;
  align-items: center;

  #quantity {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-width: 40px;
    text-align: center;
    border-left: 1px solid #e1e1e9;
    border-right: 1px solid #e1e1e9;
    padding-bottom: 4px;
  }

  #minus,
  #plus {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 25px;
    font-size: 18px;
    font-weight: bold;
    color: var(--cm-primary);
    padding-bottom: 4px;
    cursor: pointer;
  }
}
